import { useContext, useMemo } from 'react';
import { ApiContext } from '../App';
import { Configuration, DefaultApi } from '../generated-backend-api';
import { baseUrl } from '../config';

export const useClient = () => {
  const { getAccessToken } = useContext(ApiContext);

  const client = useMemo(() => {
    return new DefaultApi(
      new Configuration({
        basePath: baseUrl,
        middleware: [
          {
            pre: async (ctx) => {
              const token: string = await getAccessToken();

              const headers = new Headers(ctx.init.headers);
              headers.append('Authorization', `Bearer ${token}`);

              return {
                init: {
                  ...ctx.init,
                  headers,
                },
                url: ctx.url,
              };
            },
          },
        ],
      })
    );
  }, [getAccessToken]);

  return client;
};
