import deepmerge from 'deepmerge';
import {
  AppActions,
  AppState,
  VirtualCompositeEditListResult,
  VirtualCompositeUploadsListResult,
} from './app-state';
import { unreachable } from './util/common';

export function getInitialState(): AppState {
  const s = localStorage.getItem('state');

  let storedState: Partial<AppState> = {};
  if (s) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      storedState = JSON.parse(s);
    } catch (_e) {
      console.warn('invalid state in storage');
    }
  }

  const initialState: AppState = {
    deliveries: {
      deliveriesList: {
        status: 'uninitialized',
      },
      deliveryDataMap: {},
      deliveryFsAssets: { status: 'uninitialized' },
    },
    tools: {
      searchFS: { status: 'uninitialized' },
      searchIDAM: { status: 'uninitialized' },
    },
    assetStatus: {
      searchExtApi: { status: 'uninitialized' },
      searchFsFull: { status: 'uninitialized' },
      imccImage: { status: 'uninitialized' },
      searchBynder: { status: 'uninitialized' },
    },
    search: {
      results: { status: 'uninitialized' },
    },
    search2: {
      searchResult: { status: 'uninitialized' },
    },
    searchCropPage: {
      searchResult: { status: 'uninitialized' },
    },
    uploadsList: { status: 'uninitialized' },
    upload: {
      jobs: [],
      searchFS: { status: 'uninitialized' },
      searchIDAMImage: { status: 'uninitialized' },
    },
    uploadRetryMap: {},
    bynderMeta: { status: 'uninitialized' },
    edit: {
      assets: { status: 'uninitialized' },
      assetsExtName: { status: 'uninitialized' },
      editList: { status: 'uninitialized' },
    },
    userSettings: {
      usageRights: ['Global'],
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return deepmerge(initialState, storedState, { arrayMerge: (_a, b) => b }); // merge arrays by replacing with whatever is in stored state
}

export function stateReducer(state: AppState, action: AppActions): AppState {
  const newState = reducer(state, action);

  const { upload } = newState;

  localStorage.setItem(
    'state',
    JSON.stringify({ upload: { jobs: upload.jobs }, userSettings: newState.userSettings })
  );

  return newState;
}

export function reducer(state: AppState, action: AppActions): AppState {
  console.log(`action ${action.type}`, action);
  switch (action.type) {
    case 'login':
      return { ...state };
    case 'logout':
      return getInitialState();

    case 'deliveries-loading':
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          deliveriesList: {
            status: 'loading',
          },
        },
      };

    case 'deliveries-loaded':
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          deliveriesList: { status: 'loaded', data: action.result },
        },
      };
    case 'delivery-loaded':
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          deliveryDataMap: {
            ...state.deliveries.deliveryDataMap,
            [action.id]: {
              data: action.result,
              id: action.id,
              status: 'loaded',
            },
          },
        },
      };

    case 'delivery-loading':
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          deliveryDataMap: {
            ...state.deliveries.deliveryDataMap,
            [action.id]: {
              id: action.id,
              status: 'loading',
            },
          },
        },
      };

    case 'asset-status-search-extapi-loading': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          searchExtApi: { status: 'loading', options: action.options },
        },
      };
    }

    case 'asset-status-search-extapi-loaded': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          searchExtApi: {
            status: 'loaded',
            data: action.result,
            options: action.options,
            raw: action.raw,
          },
        },
      };
    }

    case 'search-fs-full-loading': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          searchFsFull: { status: 'loading', phs: action.phs },
        },
      };
    }

    case 'search-fs-full-loaded': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          searchFsFull: {
            status: 'loaded',
            data: action.result,
            phs: action.phs,
            raw: action.raw,
          },
        },
      };
    }

    case 'imcc-image-loading': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          imccImage: { status: 'loading', phs: action.phs },
        },
      };
    }

    case 'imcc-image-loaded': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          imccImage: {
            status: 'loaded',
            data: action.result,
            phs: action.phs,
            raw: action.raw,
          },
        },
      };
    }

    case 'search-bynder-loading': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          searchBynder: { status: 'loading', ids: action.ids },
        },
      };
    }

    case 'search-bynder-loaded': {
      return {
        ...state,
        assetStatus: {
          ...state.assetStatus,
          searchBynder: {
            status: 'loaded',
            data: action.result,
            ids: action.ids,
          },
        },
      };
    }

    case 'delivery-fs-assets-loading': {
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          deliveryFsAssets: {
            status: 'loading',
            deliveryId: action.deliveryId,
            phs: action.names,
          },
        },
      };
    }

    case 'delivery-fs-assets-loaded': {
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          deliveryFsAssets: {
            status: 'loaded',
            deliveryId: action.deliveryId,
            phs: action.names,
            data: action.result,
          },
        },
      };
    }

    case 'tools-assets-loading': {
      return {
        ...state,
        tools: {
          ...state.tools,
          searchFS: { status: 'loading', phs: action.names },
          searchIDAM: { status: 'loading', phs: action.names },
        },
      };
    }

    case 'tools-assets-loaded': {
      return {
        ...state,
        tools: {
          ...state.tools,
          searchFS: { status: 'loaded', phs: action.names, data: action.result.fsAssets },
          searchIDAM: { status: 'loaded', phs: action.names, data: action.result.idamAssets },
        },
      };
    }

    case 'uploads-list-loading': {
      return {
        ...state,
        uploadsList: {
          status: 'loading',
          prev: action.prev,
          lastResult: undefined,
        },
      };
    }

    case 'uploads-list-loaded': {
      let data: VirtualCompositeUploadsListResult;

      if (action.result.type !== 'bynder-asset-upload-job-list-response') {
        data = action.result;
      } else {
        if (action.startBefore && state.uploadsList.prev) {
          data = {
            type: 'composite-uploads-list-result',
            isError: false,
            result: [...state.uploadsList.prev, ...action.result.result],
          };
        } else {
          data = {
            type: 'composite-uploads-list-result',
            isError: false,
            result: [...action.result.result],
          };
        }
      }

      return {
        ...state,
        uploadsList: { status: 'loaded', data, lastResult: action.result },
      };
    }

    case 'edit-list-loading': {
      return {
        ...state,
        edit: {
          ...state.edit,
          editList: {
            status: 'loading',
            prev: action.prev,
            lastResult: undefined,
          },
        },
      };
    }

    case 'edit-list-loaded': {
      let data: VirtualCompositeEditListResult;

      if (action.result.type !== 'bynder-asset-edit-job-list-response') {
        data = action.result;
      } else {
        if (action.startBefore && state.edit.editList.prev) {
          data = {
            type: 'composite-edit-list-result',
            isError: false,
            result: [...state.edit.editList.prev, ...action.result.result],
          };
        } else {
          data = {
            type: 'composite-edit-list-result',
            isError: false,
            result: [...action.result.result],
          };
        }
      }

      return {
        ...state,
        edit: {
          ...state.edit,
          editList: { status: 'loaded', data, lastResult: action.result },
        },
      };
    }

    case 'set-upload-jobs': {
      return {
        ...state,
        upload: {
          ...state.upload,
          jobs: action.jobs,
        },
      };
    }

    case 'bynder-meta-loading': {
      return {
        ...state,
        upload: {
          ...state.upload,
        },
        bynderMeta: { status: 'loading' },
      };
    }

    case 'bynder-meta-loaded': {
      return {
        ...state,
        upload: {
          ...state.upload,
        },
        bynderMeta: { status: 'loaded', data: action.result },
      };
    }

    case 'upload-search-fs-loading': {
      return {
        ...state,
        upload: {
          ...state.upload,
          searchFS: { status: 'loading', phs: action.phs },
        },
      };
    }

    case 'upload-search-fs-loaded': {
      return {
        ...state,
        upload: {
          ...state.upload,
          searchFS: { status: 'loaded', phs: action.phs, data: action.result },
        },
      };
    }

    case 'retry-upload-loading': {
      return {
        ...state,
        uploadRetryMap: {
          ...state.uploadRetryMap,
          [action.id]: { status: 'loading' },
        },
      };
    }

    case 'retry-upload-loaded': {
      const m = state.uploadRetryMap;
      delete m[action.id];

      return {
        ...state,
        uploadRetryMap: m,
      };
    }

    case 'upload-search-idam-image-loading': {
      return {
        ...state,
        upload: {
          ...state.upload,
          searchIDAMImage: { status: 'loading', phs: action.phs },
        },
      };
    }

    case 'upload-search-idam-image-loaded': {
      return {
        ...state,
        upload: {
          ...state.upload,
          searchIDAMImage: { status: 'loaded', phs: action.phs, data: action.result },
        },
      };
    }

    case 'search-extapi-loading': {
      return {
        ...state,
        search: {
          ...state.search,
          results: { status: 'loading', options: action.options },
        },
      };
    }

    case 'search-extapi-loaded': {
      return {
        ...state,
        search: {
          ...state.search,
          results: {
            status: 'loaded',
            data: action.result,
            options: action.options,
          },
        },
      };
    }

    case 'edit-search-fs-loading': {
      return {
        ...state,
        edit: {
          ...state.edit,
          assets: { status: 'loading', ids: action.ids },
        },
      };
    }

    case 'edit-search-fs-loaded': {
      return {
        ...state,
        edit: {
          ...state.edit,
          assets: { status: 'loaded', data: action.result, ids: action.ids },
        },
      };
    }

    case 'search-name-ext-fs-loaded': {
      return {
        ...state,
        edit: {
          ...state.edit,
          assetsExtName: {
            status: 'loaded',
            data: action.result,
            names: action.names,
            extensions: action.extensions,
          },
        },
      };
    }

    case 'search-name-ext-fs-loading': {
      return {
        ...state,
        edit: {
          ...state.edit,
          assetsExtName: {
            status: 'loading',
            names: action.names,
            extensions: action.extensions,
          },
        },
      };
    }

    case 'search2-extapi-loading': {
      return {
        ...state,
        search2: {
          ...state.search2,
          searchResult: { status: 'loading', options: action.options },
        },
      };
    }

    case 'search2-extapi-loaded': {
      return {
        ...state,
        search2: {
          ...state.search2,
          searchResult: { status: 'loaded', options: action.options, data: action.result },
        },
      };
    }

    case 'search-crop-page-loading': {
      return {
        ...state,
        searchCropPage: {
          ...state.searchCropPage,
          searchResult: { status: 'loading', options: action.options },
        },
      };
    }

    case 'search-crop-page-loaded': {
      return {
        ...state,
        searchCropPage: {
          ...state.searchCropPage,
          searchResult: { status: 'loaded', options: action.options, data: action.result },
        },
      };
    }

    case 'update-user-settings': {
      return {
        ...state,
        userSettings: action.settings,
      };
    }
  }

  unreachable(action);
}
