import React, { useEffect, useRef, useState } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SectionWrap } from '../../SectionWrap';
import { Section } from '../../components/section/section';
import { useParams } from 'react-router-dom';
import { Loadable } from '../../util/type';
import { ProductCoordinateListType } from '../../util/product-coordinates';
import { useDebouncedCallback } from 'use-debounce';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Select, { Option } from '@ingka/select';
import { useBynder } from '../../util/bynderReactUtils';
import { BynderGetAssetResponse } from '../../util/bynder-api';
import {
  BynderMetaResult,
  CreateBynderAssetJobResult,
  GetBynderAssetUploadJobListResult,
  PIABulkItemEntry,
  PIAItemIdentifier,
  SearchPIABulkResult,
  UploadJobItem,
} from '../../generated-backend-api';
import { bynderAssetToBynderForm } from '../../util/bynder-meta';
import { useToasts } from '../../util/toastProvider';
import { bynderMetaFieldIDs } from '../../config';
import equal from 'deep-equal';
import Checkbox from '@ingka/checkbox';
import { BynderLoginButton } from '../../components/bynder-login-button';
import { CornerResizer } from '../../components/Crop/corner-resizer';
import {
  calculateCropImageDimensions,
  clamp,
  createAlignedCrop,
  CropDefinition,
  generateCropDefinitionFromPoints,
  projectPointToLineWithinBounds,
  Ratio,
  safeFractionString,
  widthHeightTopLeftToBoxPoints,
} from './math';
import {
  extractBynderProductCoords,
  generateCropName,
  productCoordinatesToPIAItemIdentifiers,
} from './util';
import { CropProduct } from './CropProduct';

// Things to explore:
// smartcrop: https://github.com/jwagner/smartcrop.js/
// undo redo: move relevant state to reducer and save N changes in memory to allow undo/redo

/**
 * Supported ratios.
 */
const BASE_RATIOS = ['16:9', '9:16', '3:4', '4:3', '1:1'] as const;

const CROP_RATIOS: Ratio[] = BASE_RATIOS.map((r) => {
  const [A, B] = r.split(':').map(Number);
  return {
    A,
    B,
    ratio: r,
  };
});

interface CropProps {
  bynderMeta: Loadable<BynderMetaResult>;
  uploadToBynder: (items: UploadJobItem[]) => Promise<CreateBynderAssetJobResult | false>;
  loadBynderMeta: () => void;
  loadPIAProducts: (itemIdentifiers: PIAItemIdentifier[]) => Promise<SearchPIABulkResult>;
  getUploadJobsByName: (name: string) => Promise<GetBynderAssetUploadJobListResult>;
}

export function Crop(props: CropProps): JSX.Element {
  const [productCoordinates, setProductCoordinates] = useState<ProductCoordinateListType>([]);

  const [hoveredProductListItemIndex, setHoveredProductListItemIndex] =
    useState<null | number>(null);

  const [draggedDotIndex, setDraggedDotIndex] = useState<null | number>(null);
  const [hoveredProductBoxIndex, setHoveredProductBoxIndex] = useState<null | number>(null);
  const [hoveredProductDotIndex, setHoveredProductDotIndex] = useState<null | number>(null);
  const [cropDragStartPosition, setCropDragStartPosition] =
    useState<{
      x: number;
      y: number;
    } | null>(null);

  const [assetLoadResult, setAssetLoadResult] = useState<
    Loadable<BynderGetAssetResponse, { id: string }>
  >({
    status: 'uninitialized',
  });

  const [isInspirationalCrop, setIsInspirationalCrop] = useState<boolean>(false);
  const [isCreatingCrop, setIsCreatingCrop] = useState(false);
  const [disabledProducts, setDisabledProducts] = useState<number[]>([]);

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const [cropDefinition, setCropDefinition] = useState<CropDefinition>({
    ratio: CROP_RATIOS[0],
    base: 0.5,
    x: 0,
    y: 0,
  });

  const [piaProducts, setPiaProducts] = useState<
    Loadable<SearchPIABulkResult, { identifiers: PIAItemIdentifier[] }>
  >({ status: 'uninitialized' });

  const imgContainerRef = useRef<HTMLDivElement>(null);

  const toastApi = useToasts();
  const bynderApi = useBynder();
  const { id } = useParams<{ id?: string }>();

  const { bynderMeta, loadBynderMeta, uploadToBynder, loadPIAProducts } = props;

  // LOADING

  // load bynder asset on page render
  useEffect(() => {
    if (!id) {
      console.warn('invalid id');
      return;
    }

    if (!bynderApi.isLoggedIn) {
      return;
    }

    // TODO(faddi): handle change in asset id, new id request could load before old one is loaded

    if (assetLoadResult.status === 'uninitialized' || assetLoadResult.id !== id) {
      setAssetLoadResult({ status: 'loading', id });

      void bynderApi.bynder.getAsset(id).then((r) => {
        setAssetLoadResult({ status: 'loaded', data: r, id });

        if (r.isError) {
          toastApi.push({
            isError: true,
            message: 'Could not load asset',
          });
          console.error(r);
          return;
        }

        const productCoords = extractBynderProductCoords(r.result);
        setProductCoordinates(productCoords);
      });
    }
  }, [id, bynderApi, assetLoadResult, toastApi]);

  // load bynder metadata if not loaded already
  useEffect(() => {
    if (bynderMeta.status === 'uninitialized') {
      loadBynderMeta();
    }
  }, [bynderMeta, loadBynderMeta]);

  // load pia data for products
  useEffect(() => {
    const piaProductIdentifiers = productCoordinatesToPIAItemIdentifiers(productCoordinates);

    if (
      piaProducts.status !== 'uninitialized' &&
      equal(piaProductIdentifiers.itemIdentifiers, piaProducts.identifiers)
    ) {
      return;
    }

    if (piaProductIdentifiers.itemIdentifiers.length === 0) {
      // fake empty result if no art nos
      setPiaProducts({
        status: 'loaded',
        identifiers: [],
        data: { isError: false, type: 'pia-bulk-search-response', response: { entities: [] } },
      });
      return;
    }

    const load = async () => {
      // show warning for skipped products if any
      if (piaProductIdentifiers.skippedProducts.length > 0) {
        toastApi.push({
          isError: true,
          message: `Skipped products due to malformed products: ${piaProductIdentifiers.skippedProducts.join(
            ', '
          )}`,
        });
      }

      // load pia product data
      setPiaProducts({
        status: 'loading',
        identifiers: piaProductIdentifiers.itemIdentifiers,
      });

      const loadResult = await loadPIAProducts(piaProductIdentifiers.itemIdentifiers);

      if (
        piaProducts.status === 'loading' ||
        (piaProducts.status === 'loaded' &&
          piaProductIdentifiers.itemIdentifiers !== piaProducts.identifiers)
      ) {
        setPiaProducts({
          status: 'loaded',
          data: loadResult,
          identifiers: piaProductIdentifiers.itemIdentifiers,
        });
      }
    };

    load().catch((e) => {
      console.error(e);
      toastApi.push({
        isError: true,
        message: 'Failed to load PIA products',
      });
    });
  }, [productCoordinates, loadPIAProducts, toastApi, piaProducts]);

  // END LOADING

  // Callbacks

  /**
   * On mouse move over image we update crop position and hotspot position if applicable.
   * The useDebouncedCallback hook ensures that we don't call this more than 60 times per second.
   */
  const debouncedMouseMove = useDebouncedCallback((e: React.MouseEvent) => {
    if (!imgContainerRef.current) {
      return;
    }

    if (cropDragStartPosition === null && draggedDotIndex === null) {
      // no need to do anything if we aren't dragging anything
      return;
    }

    const box = imgContainerRef.current.getBoundingClientRect();

    const x = (e.clientX - box.left) / box.width;
    const y = (e.clientY - box.top) / box.height;

    if (cropDragStartPosition !== null) {
      const { width, height } = calculateCropImageDimensions(cropDefinition, box.width, box.height);
      const cropWidth = width / box.width;
      const cropHeight = height / box.height;

      setCropDefinition({
        ...cropDefinition,
        x: clamp(x - cropDragStartPosition.x, 0, 1 - cropWidth),
        y: clamp(y - cropDragStartPosition.y, 0, 1 - cropHeight),
      });
    }

    if (draggedDotIndex !== null) {
      const p = productCoordinates[draggedDotIndex];

      const copy = [...productCoordinates];
      copy[draggedDotIndex].dot.x = String(
        clamp(x, Number(p.box.l), Number(p.box.l) + Number(p.box.w))
      );
      copy[draggedDotIndex].dot.y = String(
        clamp(y, Number(p.box.t), Number(p.box.t) + Number(p.box.h))
      );

      setProductCoordinates(copy);
    }
  });

  /**
   * On crop corner drag we update the crop definition.
   */
  const onCropCornerDrag = (
    e: React.MouseEvent,
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!imgContainerRef.current) {
      return;
    }

    // There is some weirdness going on with the last drag event where we get negative values, just ignoring those
    if (e.clientX <= 0 || e.clientY <= 0) {
      return;
    }

    const box = imgContainerRef.current.getBoundingClientRect();

    const { width, height } = calculateCropImageDimensions(cropDefinition, box.width, box.height);
    const left = cropDefinition.x * box.width;
    const top = cropDefinition.y * box.height;

    const points = widthHeightTopLeftToBoxPoints(width, height, top, left);

    const mousePoint = {
      x: e.clientX - box.left,
      y: e.clientY - box.top,
    };

    const imageBoxTopLeft = { x: 0, y: 0 };
    const imageBoxTopRight = { x: box.width, y: 0 };
    const imageBoxBottomRight = { x: box.width, y: box.height };
    const imageBoxBottomLeft = { x: 0, y: box.height };

    const bounds = {
      bottomLeft: imageBoxBottomLeft,
      bottomRight: imageBoxBottomRight,
      topLeft: imageBoxTopLeft,
      topRight: imageBoxTopRight,
    };

    switch (position) {
      case 'top-left':
        {
          const newTopLeft = projectPointToLineWithinBounds(
            mousePoint,
            { l1: points.topLeft, l2: points.bottomRight },
            bounds
          );

          points.topLeft = newTopLeft;
          points.bottomLeft.x = newTopLeft.x;

          points.topRight.y = newTopLeft.y;
        }
        break;
      case 'top-right':
        {
          const newTopRight = projectPointToLineWithinBounds(
            mousePoint,
            { l1: points.bottomLeft, l2: points.topRight },
            bounds
          );

          points.topRight = newTopRight;
          points.topLeft.y = newTopRight.y;

          points.bottomRight.x = newTopRight.x;
        }
        break;
      case 'bottom-left':
        {
          const newBottomLeft = projectPointToLineWithinBounds(
            mousePoint,
            { l1: points.topRight, l2: points.bottomLeft },
            bounds
          );

          points.bottomLeft = newBottomLeft;
          points.topLeft.x = newBottomLeft.x;

          points.bottomRight.y = newBottomLeft.y;
        }
        break;
      case 'bottom-right':
        {
          const newBottomRight = projectPointToLineWithinBounds(
            mousePoint,
            { l1: points.topLeft, l2: points.bottomRight },
            bounds
          );

          points.bottomRight = newBottomRight;

          points.topRight.x = newBottomRight.x;
          points.bottomLeft.y = newBottomRight.y;
        }
        break;
    }

    // calculate new cropDefinition

    const newCropDefinition = generateCropDefinitionFromPoints(points, box, cropDefinition.ratio);

    if (newCropDefinition.base > 0.05) {
      setCropDefinition(newCropDefinition);
    }
  };

  // END callbacks

  if (!bynderApi.isLoggedIn) {
    return (
      <SectionWrap>
        <BynderLoginButton />
      </SectionWrap>
    );
  }

  if (assetLoadResult.status === 'uninitialized') {
    return <SectionWrap>Asset &quot;{id}&quot; is not initialized.</SectionWrap>;
  }

  if (assetLoadResult.status === 'loading') {
    return <SectionWrap>Loading asset {id}</SectionWrap>;
  }

  if (assetLoadResult.data.isError) {
    return <SectionWrap>Could not fetch asset {assetLoadResult.data.type}</SectionWrap>;
  }
  const asset = assetLoadResult.data.result;
  const originalWidth = asset.width;
  const originalHeight = asset.height;

  if (!originalWidth || !originalHeight) {
    return (
      <SectionWrap>
        Asset {id} has invalid width ({originalWidth}) or height ({originalHeight})
      </SectionWrap>
    );
  }

  if (bynderMeta.status === 'uninitialized') {
    return <SectionWrap>Bynder meta uninitialized</SectionWrap>;
  }

  if (bynderMeta.status === 'loading') {
    return <SectionWrap>Loading meta</SectionWrap>;
  }

  if (bynderMeta.data.type !== 'bynder-meta-response') {
    return <SectionWrap>Failed to load bynder meta {bynderMeta.data.type}</SectionWrap>;
  }

  const warnings = [];

  if (piaProducts.status === 'loaded' && piaProducts.data.isError) {
    warnings.push(
      <InlineMessage
        variant={'cautionary'}
        body={<pre>{JSON.stringify(piaProducts.data, null, 4)}</pre>}
        title={'Could not load PIA products'}
      />
    );
  }

  const bynderMetaResult = bynderMeta.data.result;

  const artsWithinCrop = new Set<number>();

  const { width, height } = calculateCropImageDimensions(
    cropDefinition,
    originalWidth,
    originalHeight
  );

  // crop dimensions in the original pixel space
  const imageCropWidth = width;
  const imageCropHeight = height;

  // crop dimensions (percentages) in the original pixel space
  const cropWidthPercent = width / originalWidth;
  const cropHeightPercent = height / originalHeight;

  const cropLeftPercent = cropDefinition.x;
  const cropTopPercent = cropDefinition.y;

  // Add arts within crop to artsWithinCrop
  for (let i = 0; i < productCoordinates.length; i++) {
    const c = productCoordinates[i];
    const dot = c.dot;
    const x = Number(dot.x);
    const y = Number(dot.y);
    if (
      x >= cropLeftPercent &&
      x <= cropLeftPercent + cropWidthPercent &&
      y >= cropTopPercent &&
      y <= cropTopPercent + cropHeightPercent
    ) {
      artsWithinCrop.add(i);
    }
  }

  // create a map of pia product data for each art within the crop
  // artno(our) -> pia product data
  const piaProductMap = new Map<string, PIABulkItemEntry>();

  if (piaProducts.status === 'loaded' && piaProducts.data.type === 'pia-bulk-search-response') {
    for (const p of piaProducts.data.response.entities) {
      if (!p.content) {
        continue;
      }

      const item = p.content;

      piaProductMap.set(`${item.itemType.toLowerCase()},${item.itemNo}`, p);
    }
  }

  // Render products
  const productList = productCoordinates.map((c, index) => {
    return (
      <CropProduct
        index={index}
        isActive={artsWithinCrop.has(index) && !disabledProducts.includes(index)}
        onMouseEnter={() => {
          setHoveredProductListItemIndex(index);
        }}
        onMouseLeave={() => {
          setHoveredProductListItemIndex(null);
        }}
        onTrashClick={() => {
          const disabledSet = new Set(disabledProducts);
          if (disabledSet.has(index)) {
            disabledSet.delete(index);
          } else {
            disabledSet.add(index);
          }
          setDisabledProducts([...disabledSet]);
        }}
        piaItem={piaProductMap.get(c.prd)}
        productCoordinate={c}
        key={c.prd}
      />
    );
  });

  const alignedCrop = createAlignedCrop(
    {
      left: cropDefinition.x * originalWidth,
      top: cropDefinition.y * originalHeight,
      width: imageCropWidth,
      height: imageCropHeight,
    },
    cropDefinition.ratio,
    originalWidth,
    originalHeight
  );

  /**
   * Logic when actually creating the crop, frontend is responsible for aggregating all data and sending it to the backend
   *
   * Reference tagtool flow for meta
   * 1. (don't know if this is needed) reverse dates
   *
   * 2. (done) copy parent image channels and add shoppableapp channel, conditionally set inspirational channel
   *   if inspriration channel set
   *     add inspirational channel
   *
   *   add ShoppableApp channel
   *
   * 3. (done) set shoppable image content type if number of tags is > 0, otherwise remove it
   *
   * 4. (skip) add icom project name (is this still relevant?)
   *
   * 5. (done) set product list by articles in tags
   *
   * 6. (done) set cropInfo
   */
  const onCreateCrop = async () => {
    const parentAsset = bynderAssetToBynderForm(asset, bynderMetaResult);

    // fetch bynder download url
    const downloadUrl = await bynderApi.bynder.getAssetDownloadUrl(asset.id);

    if (downloadUrl.isError) {
      toastApi.push({
        isError: true,
        message: `Could not fetch download url. ${downloadUrl.type}`,
      });
      return;
    }

    if (asset.extension.length !== 1) {
      toastApi.push({
        isError: true,
        message: `Not supported file type ${JSON.stringify(asset.extension, null, 4)}`,
      });
      return;
    }

    const cropName = await generateCropName(
      bynderApi.bynder.searchKeyword,
      asset,
      props.getUploadJobsByName
    );

    if (cropName === null) {
      toastApi.push({
        isError: true,
        message: 'Could not generate crop name',
      });
      return;
    }

    // channels
    const parentChannels =
      parentAsset.bynderMetaPropertyOptions[
        `metaproperty.${bynderMetaFieldIDs.BYNDER_CHANNEL_META_ID}`
      ];

    const channels = new Set<string>(
      parentChannels ? parentChannels.split(',').map((q) => q.trim()) : []
    );

    channels.add(bynderMetaFieldIDs.BYNDER_CHANNEL_META_SHOPPABLE_APP_OPTION_ID);
    if (isInspirationalCrop) {
      channels.add(bynderMetaFieldIDs.BYNDER_CHANNEL_META_INSPIRATIONAL_FEED_OPTION_ID);
    } else {
      channels.delete(bynderMetaFieldIDs.BYNDER_CHANNEL_META_INSPIRATIONAL_FEED_OPTION_ID);
    }

    // content type
    const parentContentType =
      parentAsset.bynderMetaPropertyOptions[
        `metaproperty.${bynderMetaFieldIDs.BYNDER_CONTENT_TYPE_META_ID}`
      ];

    const contentTypes = new Set<string>(parentContentType ? parentContentType.split(',') : []);

    if (artsWithinCrop.size > 0) {
      contentTypes.add(bynderMetaFieldIDs.BYNDER_CONTENT_TYPE_META_SHOPPABLE_IMAGE_OPTION_ID);
    } else {
      contentTypes.delete(bynderMetaFieldIDs.BYNDER_CONTENT_TYPE_META_SHOPPABLE_IMAGE_OPTION_ID);
    }

    // recalculate box dimensions and hotspot positioning from full image to crop

    const origCropPositionX = cropDefinition.x * originalWidth;
    const origCropPositionY = cropDefinition.y * originalHeight;

    const activeProductsForCrop = productCoordinates
      .filter((_, index) => artsWithinCrop.has(index) && !disabledProducts.includes(index))
      .map((p) => {
        // find original product box dimensions in pixel space

        const origBoxWidth = Number(p.box.w) * originalWidth;
        const origBoxHeight = Number(p.box.h) * originalHeight;
        const origBoxOffsetLeft = Number(p.box.l) * originalWidth;
        const origBoxOffsetTop = Number(p.box.t) * originalHeight;

        // clamp original product box to crop, (we could be cropping some part of the product box)

        const clampedBoxX = clamp(origBoxOffsetLeft - origCropPositionX, 0, imageCropWidth);
        const clampedBoxY = clamp(origBoxOffsetTop - origCropPositionY, 0, imageCropHeight);
        const clampedBoxX1 = clamp(
          origBoxOffsetLeft - origCropPositionX + origBoxWidth,
          0,
          imageCropWidth
        );
        const clampedBoxY1 = clamp(
          origBoxOffsetTop - origCropPositionY + origBoxHeight,
          0,
          imageCropHeight
        );

        // convert clamped box to % space

        const left = clampedBoxX / imageCropWidth;
        const top = clampedBoxY / imageCropHeight;
        const width = (clampedBoxX1 - clampedBoxX) / imageCropWidth;
        const height = (clampedBoxY1 - clampedBoxY) / imageCropHeight;

        // move hotspot

        // calculate offset within crop and add crop offset, (hotspots are positioned relative to crop)
        const hotspotX = Number(p.dot.x) * originalWidth;
        const hotspotY = Number(p.dot.y) * originalHeight;

        const hotspotXWithinBoxInCrop = (hotspotX - origCropPositionX) / imageCropWidth;
        const hotspotYWithinBoxInCrop = (hotspotY - origCropPositionY) / imageCropHeight;

        return {
          ...p,
          dot: { x: hotspotXWithinBoxInCrop.toFixed(2), y: hotspotYWithinBoxInCrop.toFixed(2) },
          box: { l: left.toFixed(2), t: top.toFixed(2), w: width.toFixed(2), h: height.toFixed(2) },
        };
      });

    // Extract product names and artnos from piadata for products within crop

    const productNames: string[] = [];
    const artNos: string[] = [];

    for (const p of activeProductsForCrop) {
      const piaProduct = piaProductMap.get(p.prd);

      const productName = piaProduct?.content?.productNameLocal?.find(
        (q) => q.productName
      )?.productName;

      const itemNo = piaProduct?.content?.itemNo;
      const itemType = piaProduct?.content?.itemType;

      if (!productName || !itemNo || !itemType) {
        toastApi.push({
          isError: true,
          message: `Could not find required information for product ${p.prd} in PIA.`,
        });
        console.warn({ piaProduct, productName, itemNo, itemType });
        return;
      }

      productNames.push(productName);
      artNos.push(`${itemType.toLowerCase()}.${itemNo}`);
    }

    // Construct upload payload

    const payload: UploadJobItem = {
      name: cropName,
      extension: asset.extension[0],
      type: 'upload-job',
      url: downloadUrl.result.s3_file,
      crop: {
        x: alignedCrop.left,
        y: alignedCrop.top,
        width: alignedCrop.width,
        height: alignedCrop.height,
      },
      originalName: asset.name,
      description: asset.description,
      tags: asset.tags,
      bynderUploadMeta: {
        ...parentAsset.bynderMetaProperties,
        ...parentAsset.bynderMetaPropertyOptions,
        ...{
          [`metaproperty.${bynderMetaFieldIDs.BYNDER_PRODUCT_COORDINATES_META_ID}`]:
            JSON.stringify(activeProductsForCrop),
          [`metaproperty.${bynderMetaFieldIDs.BYNDER_CHANNEL_META_ID}`]: [...channels].join(','),
          [`metaproperty.${bynderMetaFieldIDs.BYNDER_CONTENT_TYPE_META_ID}`]: [
            ...contentTypes,
          ].join(','),
          [`metaproperty.${bynderMetaFieldIDs.BYNDER_PRODUCT_NAME_META_ID}`]:
            productNames.join(','),
          [`metaproperty.${bynderMetaFieldIDs.BYNDER_ITEM_NO_META_ID}`]: artNos.join(','),
          [`metaproperty.${bynderMetaFieldIDs.BYNDER_CROP_INFO_META_ID}`]: JSON.stringify({
            cropRegion: {
              l: origCropPositionX.toFixed(0),
              t: origCropPositionY.toFixed(0),
              w: imageCropWidth.toFixed(0),
              h: imageCropHeight.toFixed(0),
            },
            croppedBy: bynderApi.user.data.email,
            original: asset.id,
            originalName: parentAsset.bynderProperties.name,
          }),
        },
      },
    };

    const uploadResult = await uploadToBynder([payload]);

    if (!uploadResult) {
      toastApi.push({
        isError: true,
        message: 'Could not upload to bynder',
      });
      return;
    }

    if (uploadResult.type !== 'create-bynder-job-response') {
      toastApi.push({
        isError: true,
        message: `Could not upload to bynder. ${uploadResult.type}`,
      });
      return;
    }

    toastApi.push({
      isError: false,
      message: 'Upload queued.',
    });
  };

  // END - onCreateCrop

  const domImageWidth = imgContainerRef.current?.clientWidth
    ? imgContainerRef.current.clientWidth
    : 1;
  const domImageHeight = imgContainerRef.current?.clientHeight
    ? imgContainerRef.current.clientHeight
    : 1;

  const domImageCropDimensions = calculateCropImageDimensions(
    cropDefinition,
    domImageWidth,
    domImageHeight
  );

  return (
    <SectionWrap>
      <Section>
        <h3>Crop tool</h3>
      </Section>
      <Section>
        <h4>{asset.name}</h4>
        <div>
          <div>
            Dimensions: {originalWidth} x {originalHeight}
          </div>
          <div> Ratio: {(originalWidth / originalHeight).toFixed(2)}</div>
        </div>
      </Section>
      {warnings.length > 0 ? <Section>{warnings}</Section> : null}
      <Section size="large">
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
          `}
        >
          <div
            css={css`
              min-width: 600px;
              display: flex;
            `}
          >
            <div
              css={css`
                margin-right: 40px;
              `}
            >
              <Select
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  const ratio =
                    CROP_RATIOS.find((r) => r.ratio === e.currentTarget.value) || CROP_RATIOS[0];

                  setCropDefinition({
                    ...cropDefinition,
                    x: 0,
                    y: 0,
                    base: 0.5,
                    ratio: { ...ratio },
                  });
                }}
                id="cropRatioSelect"
                label="Select a crop ratio"
                value={cropDefinition.ratio.ratio}
                css={css`
                  max-width: 300px;
                  margin-bottom: 20px;
                `}
              >
                {CROP_RATIOS.map((r) => (
                  <Option key={r.ratio} value={r.ratio} name={r.ratio} />
                ))}
              </Select>
              <div
                css={css`
                  margin-bottom: 20px;
                `}
              >
                <Checkbox
                  id={`is_inspirational_crop`}
                  label={'Inspirational crop'}
                  value={''}
                  onClick={() => {
                    setIsInspirationalCrop(!isInspirationalCrop);
                  }}
                />
              </div>

              <Button
                type="primary"
                disabled={isCreatingCrop}
                text={`Create ${asset.name} crop.`}
                onClick={() => {
                  setIsCreatingCrop(true);
                  onCreateCrop()
                    .then(() => {
                      setIsCreatingCrop(false);
                    })
                    .catch((e) => {
                      console.error(e);
                      setIsCreatingCrop(false);
                    });
                }}
              />
            </div>
          </div>

          <div
            css={css`
              margin-right: 20px;
            `}
          >
            <div
              css={css`
                margin-bottom: 20px;
              `}
            >
              <div>
                <b>Crop approximate:</b>
              </div>
              <div>
                Position: ({(cropDefinition.x * originalWidth).toFixed(2)},{' '}
                {(cropDefinition.y * originalHeight).toFixed(2)})
              </div>
              <div>
                Dimensions: {(cropWidthPercent * originalWidth).toFixed(2)},{' x '}
                {(cropHeightPercent * originalHeight).toFixed(2)}
              </div>
              <div>
                Ratio:{' '}
                {safeFractionString(
                  Math.floor(cropWidthPercent * originalWidth),
                  Math.floor(cropHeightPercent * originalHeight)
                )}
              </div>
            </div>
            <div>
              <div>
                <b>Crop exact:</b>
              </div>
              <div>
                Position: ({alignedCrop.left.toFixed(2)}, {alignedCrop.top.toFixed(2)})
              </div>
              <div>
                Dimensions: {alignedCrop.width.toFixed(0)} x {alignedCrop.height.toFixed(0)}
              </div>
              <div>Ratio: {safeFractionString(alignedCrop.width, alignedCrop.height)}</div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div
          css={css`
            display: flex;
          `}
        >
          <div>
            <div
              css={css`
                width: 600px;
                position: relative;
                margin-right: 40px;
                user-select: none;
                overflow: hidden;
                display: flex;
              `}
              ref={imgContainerRef}
              onMouseMove={debouncedMouseMove}
              onMouseUp={() => {
                setDraggedDotIndex(null);
                setCropDragStartPosition(null);
              }}
            >
              {/* img */}

              {!isImageLoaded ? <div>Loading image...</div> : null}
              <img
                css={css`
                  width: 100%;
                  object-fit: contain;
                  object-position: top;
                `}
                src={asset.thumbnails?.webimage}
                alt={asset.name}
                onLoad={() => {
                  setIsImageLoaded(true);
                }}
              />

              {/* crop */}

              <div
                css={css`
                  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
                  position: absolute;
                  z-index: 15;
                  border: 1px dashed #fff;
                  cursor: move;
                `}
                style={{
                  top: `${cropDefinition.y * 100}%`,
                  left: `${cropDefinition.x * 100}%`,
                  width: `${domImageCropDimensions.width}px`,
                  height: `${domImageCropDimensions.height}px`,
                }}
                onMouseDown={(e) => {
                  if (!imgContainerRef.current) {
                    return;
                  }

                  const box = imgContainerRef.current.getBoundingClientRect();

                  setCropDragStartPosition({
                    x: (e.clientX - box.left) / box.width - cropDefinition.x,
                    y: (e.clientY - box.top) / box.height - cropDefinition.y,
                  });
                }}
              >
                <CornerResizer position="bottom-right" onDrag={onCropCornerDrag} />
                <CornerResizer position="bottom-left" onDrag={onCropCornerDrag} />
                <CornerResizer position="top-right" onDrag={onCropCornerDrag} />
                <CornerResizer position="top-left" onDrag={onCropCornerDrag} />
              </div>

              {/* dots */}
              {productCoordinates.map((c, index) => {
                if (disabledProducts.includes(index)) {
                  return null;
                }

                const w = 16;

                let color = artsWithinCrop.has(index) ? '#fff' : '#666';

                if (hoveredProductListItemIndex === index) {
                  color = '#333';
                }

                return (
                  <div
                    key={index}
                    onMouseDown={() => {
                      if (draggedDotIndex !== index) {
                        setDraggedDotIndex(index);
                      }
                    }}
                    onMouseEnter={() => {
                      setHoveredProductDotIndex(index);
                    }}
                    onMouseLeave={() => {
                      setHoveredProductDotIndex(null);
                    }}
                    css={css`
                      background-color: ${color};
                      position: absolute;
                      cursor: move;
                      border-radius: 50%;
                      border: 1px solid #999;
                      z-index: 100;
                    `}
                    style={{
                      width: `${w}px`,
                      height: `${w}px`,
                      transform: `translate(-${w / 2}px, -${w / 2}px)`,
                      top: `${Number(c.dot.y) * 100}%`,
                      left: `${Number(c.dot.x) * 100}%`,
                    }}
                  />
                );
              })}

              {/* boxes */}
              {productCoordinates.map((c, index) => {
                if (disabledProducts.includes(index)) {
                  return null;
                }
                return (
                  <div
                    key={index}
                    onMouseEnter={() => {
                      setHoveredProductBoxIndex(index);
                    }}
                    onMouseLeave={() => {
                      setHoveredProductBoxIndex(null);
                    }}
                    css={css`
                      position: absolute;
                      z-index: 10;
                      ${draggedDotIndex === index ||
                      hoveredProductListItemIndex === index ||
                      hoveredProductBoxIndex === index ||
                      hoveredProductDotIndex === index
                        ? 'border: 1px solid #999;'
                        : ''}
                    `}
                    style={{
                      top: `${Number(c.box.t) * 100}%`,
                      left: `${Number(c.box.l) * 100}%`,
                      width: `${Number(c.box.w) * 100}%`,
                      height: `${Number(c.box.h) * 100}%`,
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div
            css={css`
              width: 100%;
            `}
          >
            <Section>
              <div
                css={css`
                  overflow-y: scroll;
                `}
                style={{ maxHeight: `${imgContainerRef.current?.clientHeight ?? 600}px` }}
              >
                {productList}
              </div>
            </Section>
          </div>
        </div>
      </Section>
    </SectionWrap>
  );
}
