import { useQuery } from '@tanstack/react-query';
import { prioritizedSearchableFields } from '../components/Search/helpers';
import { ElasticSearchMappingResult } from '../generated-backend-api';
import { hasKey } from '../util/type';
import { useClient } from './api-client';

const ONE_HOUR_MS = 60 * 60 * 1000;

export const useSearchableFields = () => {
  const client = useClient();
  return useQuery({
    queryKey: ['searchableFields'],
    queryFn: async (): Promise<string[]> => {
      const data: ElasticSearchMappingResult = await client.ElasticSearchMapping({});
      return data.isError ? [] : mapSearchableFields(data.result);
    },
    staleTime: ONE_HOUR_MS,
    initialData: [], // Set initial data to ensure type of data is always string[]
    initialDataUpdatedAt: 0, // Mark initial data as expired to force an initial refetch of the data
  });
};

const mapSearchableFields = (mapping: unknown): string[] => {
  const fields = extractFieldsFromElasticMapping(mapping).sort();

  // sanity check of prioritizedSearchableFields
  for (const f of prioritizedSearchableFields) {
    if (!fields.includes(f)) {
      console.error(
        `prioritizedSearchableFields contains field ${f} which is not in searchableFields`
      );
    }
  }

  return fields;
};

function extractFieldsFromElasticMapping(data: unknown): string[] {
  if (hasKey('properties', data) && isRecord(data.properties)) {
    return Object.entries(data.properties).flatMap(([k, v]) => {
      const subFields = extractFieldsFromElasticMapping(v);

      if (subFields.length === 0) {
        return [k];
      } else {
        return [
          k,
          ...subFields.map((s) => {
            return `${k}.${s}`;
          }),
        ];
      }
    });
  }

  return [];
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
