/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Button from '@ingka/button';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FrontendSearchState, Search } from '../components/Search/Search';
import { Section } from '../components/section/section';
import { SearchExtApi2Result, SearchRequestBody } from '../generated-backend-api';
import useUrlSearchQuery from '../hooks/useUrlSearchQuery';
import { SectionWrap } from '../SectionWrap';
import { Loadable } from '../util/type';
import { searchRequestBodyToFrontendSearchState } from '../components/Search/helpers';

interface Props {
  search: (opts: SearchRequestBody) => Promise<void> | undefined;
  results: Loadable<
    SearchExtApi2Result,
    {
      options: SearchRequestBody;
    }
  >;
  usageRights: string[];
  requestingMarket: string | undefined;
}

const CropIndexPage = (props: Props) => {
  const { search } = useLocation();
  const [getQuery, setUrlQuery] = useUrlSearchQuery();

  const urlQuery = React.useMemo(getQuery, [search, getQuery]);

  const [searchState, setSearchState] = useState<FrontendSearchState>(
    searchRequestBodyToFrontendSearchState(
      urlQuery !== null
        ? urlQuery
        : {
            query: { name: { prefix: 'ph' } },
            usageRights: props.usageRights,
            requestingMarket: !props.requestingMarket ? '' : props.requestingMarket,
          }
    )
  );

  return (
    <>
      <SectionWrap>
        <Section size="large">
          <Section>
            <h2>Find asset to crop in bynder boards.</h2>
          </Section>
          <Link
            to="/crop/boards"
            css={css`
              text-decoration: none;
            `}
          >
            <Button small>Boards</Button>
          </Link>
        </Section>
      </SectionWrap>

      <Search
        {...props}
        title="Search assets to crop"
        searchState={searchState}
        setSearchState={setSearchState}
        urlQuery={urlQuery}
        setUrlQuery={setUrlQuery}
        cropPage
      />
    </>
  );
};
export default CropIndexPage;
