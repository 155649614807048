import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { MetaProperty } from '../generated-backend-api';
import { getOptionMap } from '../meta-options';

interface ITemplateSummaryProps {
  values: { [key: string]: string[] };
  propMap: Map<string, MetaProperty>;
  description?: string;
  name?: string;
  originalName?: string;
  limited?: boolean;
  limitedDate?: string;
  tags?: string[];
}

function Field(props: { label: string; value: string }) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &:hover {
          box-shadow: 0px 1px 1px -1px #000;
        }
      `}
    >
      <span
        css={css`
          padding-right: 10px;
        `}
      >
        {props.label}
      </span>{' '}
      <span>{props.value}</span>
    </div>
  );
}

export function TemplateSummary(props: ITemplateSummaryProps): JSX.Element {
  const { values, propMap } = props;

  const entries = Object.entries(values);
  entries.sort((a, b) => {
    const ap = propMap.get(a[0]);
    const bp = propMap.get(b[0]);

    if (ap && bp) {
      return ap.zindex - bp.zindex;
    }

    return 0;
  });

  const bynderDefaults = [];

  if (typeof props.name !== 'undefined') {
    bynderDefaults.push(<Field key={'name'} label={'Name'} value={props.name} />);
  }

  if (typeof props.originalName !== 'undefined') {
    bynderDefaults.push(
      <Field key={'original_name'} label={'Original name'} value={props.originalName} />
    );
  }

  if (typeof props.description !== 'undefined') {
    bynderDefaults.push(
      <Field key={'description'} label={'Description'} value={props.description} />
    );
  }

  if (typeof props.limited !== 'undefined') {
    bynderDefaults.push(
      <Field key={'limited'} label={'Limited'} value={props.limited ? 'Yes' : 'No'} />
    );
  }

  if (typeof props.limitedDate !== 'undefined') {
    bynderDefaults.push(
      <Field key={'limited_date'} label={'Limited date'} value={props.limitedDate} />
    );
  }

  if (typeof props.tags !== 'undefined') {
    bynderDefaults.push(<Field key={'tags'} label={'Tags'} value={props.tags.join(', ')} />);
  }

  const propValues = entries.map(([id, v]) => {
    const prop = propMap.get(id);

    if (!prop) {
      return (
        <div key={id}>
          Invalid prop id {id} with value {JSON.stringify(v)}
        </div>
      );
    }

    const optMap = getOptionMap(prop);

    const valueLabels = v.map((value) => {
      const option = optMap.get(value);

      if (!option) {
        return value;
      } else {
        return option.label;
      }
    });

    return <Field key={id} label={prop.label} value={valueLabels.join(', ')} />;
  });

  const allVals = [...bynderDefaults, ...propValues];

  return <div css={css``}>{allVals}</div>;
}
