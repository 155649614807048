import { MetaProperty } from './generated-backend-api';

const optMapCache: Map<string, Map<string, OptionType>> = new Map<
  string,
  Map<string, OptionType>
>();

type OptionType = { label: string; value: string };

export function getOptionMap(meta: MetaProperty): Map<string, OptionType> {
  const z = optMapCache.get(meta.id);

  if (z) {
    return z;
  }

  const oMap: Map<string, OptionType> = new Map(
    meta.options.map((q) => {
      return [q.id, { label: q.displayLabel, value: q.id }] as [string, OptionType];
    })
  );

  optMapCache.set(meta.id, oMap);

  return oMap;
}
