import React, { ReactElement } from 'react';
import { Loadable } from '../util/type';

interface WithLoadedLoadableProps<T, Q> {
  loadable: Loadable<T, Q>;
  fallback: React.ReactElement;
  uninitialized?: React.ReactElement;
  children: (children: T) => React.ReactElement;
}

export const WithLoadedLoadable = <T, Q>(
  props: WithLoadedLoadableProps<T, Q>
): ReactElement | null => {
  if (props.loadable.status === 'uninitialized') {
    return props.uninitialized ?? null;
  }

  if (props.loadable.status !== 'loaded') {
    return props.fallback;
  }

  return props.children(props.loadable.data);
};
