import { DATE_FORMAT } from '../components/meta-input';
import { MetaBag } from '../components/upload-form';
import { bynderMetaFieldIDs } from '../config';
import {
  BynderMetaResponse,
  BynderMetaResult,
  IDAMAssetInterface,
  IDAMData,
  MetaOption,
  MetaProperty,
} from '../generated-backend-api';
import { IResult, ok } from './type';
import { extDayjs as dayjs } from './dayjs';
import { getUniqueKey } from './generate-unique-name-job-item';

export type IDAMKey =
  | 'assetInfo.image.cameraShot'
  | 'assetInfo.image.context.commonSeasonalActivity'
  | 'assetInfo.image.context.longTermPriority'
  | 'assetInfo.image.mainHomeFurnishingActivity'
  | 'files.renditions.0.orientation'
  | 'assetInfo.image.priceLevel'
  | 'assetInfo.image.roomType'
  | 'assetInfo.image.season'
  | 'assetInfo.image.styleGroup'
  | 'hasAnimals'
  | 'hasPeople';

const IDAMImageAttributeValueToBynderOptionTable: Record<string, string> = {
  'Close-up': 'CloseUp',
  'Extreme close-up': 'ExtremeCloseUp',
  'Full shot': 'FullShot',
  'Medium shot': 'MediumShot',
  'Back to college': 'BackToCollege',
  'Back to school': 'BackToSchool',
  Celebrations: 'Celebrations',
  'Enjoying my outdoor space': 'EnjoyingMyOutdoorSpace',
  'Preparing my outdoor space': 'PreparingMyOutdoorSpace',
  'Refresh Indoors (dark to light)': 'RefreshIndoorsDarkToLight',
  'Refresh Indoors (light to dark)': 'RefreshIndoorsLightToDark',
  'Living with children': 'LivingWithChildren',
  'Organise your living': 'OrganiseYourLiving',
  'Small space living': 'SmallSpaceLiving',
  'Come & go': 'ComeGo',
  Cook: 'Cook',
  Eat: 'Eat',
  Hobby: 'Hobby',
  'Personal care': 'PersonalCare',
  'Play & create & learn': 'PlayCreateLearn',
  Sleep: 'Sleep',
  Socialise: 'Socialise',
  'Stay healthy': 'StayHealthy',
  'Store & organise': 'StoreOrganise',
  'Wash & clean': 'WashClean',
  Work: 'Work',
  landscape: 'Landscape',
  portrait: 'Portrait',
  square: 'Square',
  // '': 'TruePeople',
  // '': 'FalsePeople',
  // '': 'TruePets',
  // '': 'FalsePets',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  BTI: 'BTI',
  "Baby and Children's room": 'BabyChildrensRoom',
  Bathroom: 'Bathroom',
  Bedroom: 'Bedroom',
  Dining: 'Dining',
  Hallway: 'Hallway',
  Kitchen: 'Kitchen',
  'Living room': 'LivingRoom',
  Outdoor: 'Outdoor',
  Workspace: 'Workspace',
  Spring: 'Spring',
  Summer: 'Summer',
  Winter: 'Winter',
  Autumn: 'Autumn',
  'International modern': 'InternationalModern',
  'International traditional': 'InternationalTraditional',
  'Scandinavian modern': 'ScandinavianModern',
  'Scandinavian traditional': 'ScandinavianTraditional',
};

interface NotFoundProp {
  isError: true;
  type: 'not-found';
  message: string;
}

function getMetadataProperty(
  id: string,
  bynderMeta: BynderMetaResponse,
  descriptiveText: string
): NotFoundProp | IResult<MetaProperty> {
  const prop = Object.values(bynderMeta.result).find((p) => p.id === id);

  if (!prop) {
    return {
      isError: true,
      type: 'not-found',
      message: `Could not find '${descriptiveText}' in bynder meta with id '${id}'`,
    };
  }

  return ok(prop);
}

interface TranslatedData {
  data: {
    [key: string]: MetaBag;
  };
  columns: string[];
}

export function flattenObject(obj: unknown, prefix = ''): Record<string, string> {
  if (typeof obj !== 'object' || obj === null) {
    return {};
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flattenObject(value, newKey));
    } else {
      acc[newKey] = String(value);
    }

    return acc;
  }, {} as Record<string, string>);
}

export function getOptionForIDAMAttributeValue(
  key: string,
  value: string | boolean,
  prop: MetaProperty
): MetaOption | undefined {
  const f = (name: string) => {
    return prop.options.find((q) => {
      const v1 = q.name && q.name.toString().replace(/[-\s]/g, '').toLowerCase();
      const v2 = name && name.toString().replace(/[-\s]/g, '').toLowerCase();
      return v1 === v2;
    });
  };

  if (typeof value === 'boolean') {
    if (key === 'hasAnimals') {
      return f(value === true ? 'TrueAnimals' : 'FalseAnimals');
    }

    if (key === 'hasPeople') {
      return f(value === true ? 'TruePeople' : 'FalsePeople');
    }
  } else {
    return f(value);
  }

  return undefined;
}

const featureKeys = ['hasAnimals', 'hasPeople'];

export const extractBynderValue = (idamKey: IDAMKey, idamData: IDAMData, prop: MetaProperty) => {
  const flattenedObject = flattenObject(idamData);
  const value = flattenedObject[idamKey];

  const attrValues: { prop: MetaProperty; attrValue: MetaOption | undefined; value: string }[] = [];

  if (typeof value === 'undefined') {
    if (featureKeys.includes(idamKey)) {
      const hasValue =
        idamData.assetInfo?.image?.features?.includes(
          idamKey === 'hasAnimals' ? 'ANIMALS' : 'PEOPLE'
        ) || false;
      const attrValue = getOptionForIDAMAttributeValue(idamKey, hasValue, prop);
      attrValues.push({ prop, attrValue, value: String(value) });
    }

    return attrValues;
  }

  if (Array.isArray(value)) {
    value.forEach((v: string) => {
      const attrValue = getOptionForIDAMAttributeValue(idamKey, v, prop);
      attrValues.push({ prop, attrValue, value: v });
    });
  } else {
    const attrValue = getOptionForIDAMAttributeValue(idamKey, value, prop);
    attrValues.push({ prop, attrValue, value: String(value) });
  }

  return attrValues;
};

export function translateIDAMFieldsToBynder(
  idamImageDataArray: IDAMAssetInterface[],
  bynderMeta: BynderMetaResponse
): TranslatedData {
  const {
    cameraShotProp,
    commonSeasonalActivityProp,
    hasAnimalsProp,
    hasPeopleProp,
    launchProp,
    longTermPriorityProp,
    mainHFActivityPrimaryProp,
    objectiveProp,
    orientationProp,
    priceLevelProp,
    releaseProp,
    roomTypeProp,
    seasonICOM,
    styleGroup,
  } = getFieldsFromMeta(bynderMeta);

  const newData: { [key: string]: MetaBag } = {};
  const activeCols = new Set<string>();

  for (const idamData of idamImageDataArray) {
    const bagOfMeta: MetaBag = {};

    // release

    if (
      releaseProp.isError === false &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      idamData.data?.assetInfo?.image?.context?.usageRights?.releaseDate
    ) {
      bagOfMeta[releaseProp.result.id] = [
        dayjs(idamData.data.assetInfo.image.context.usageRights.releaseDate).format(DATE_FORMAT),
      ];
      activeCols.add(releaseProp.result.id);
    }

    // launch

    const launch = idamData.data.assetInfo?.image?.context?.launch;
    const fiscalYear = idamData.data.assetInfo?.image?.context?.fiscalYear;

    if (launchProp.isError === false && launch && fiscalYear) {
      const l = `${launch}${fiscalYear}`;

      const option = launchProp.result.options.find((o) => o.name === l);

      if (!option) {
        console.error(
          `Could not find launch ${l} in property ${launchProp.result.name}`,
          launchProp,
          l,
          idamData.data
        );
      } else {
        bagOfMeta[launchProp.result.id] = [option.id];
        activeCols.add(launchProp.result.id);
      }
    }

    // objective

    const objective = idamData.data.assetInfo?.image?.context?.marketingObjective;

    if (objectiveProp.isError === false && objective) {
      const option = objectiveProp.result.options.find((o) => o.name === objective);

      if (!option) {
        console.error(
          `Could not find objective ${objective} in property ${objectiveProp.result.name}`,
          objectiveProp,
          idamData
        );
      } else {
        bagOfMeta[objectiveProp.result.id] = [option.id];
        activeCols.add(objectiveProp.result.id);
      }
    }

    const allProps: Array<[IDAMKey, GetMetadataPropertyReturnType]> = [
      ['assetInfo.image.cameraShot', cameraShotProp],
      ['assetInfo.image.context.commonSeasonalActivity', commonSeasonalActivityProp],
      ['hasAnimals', hasAnimalsProp],
      ['hasPeople', hasPeopleProp],
      ['assetInfo.image.context.longTermPriority', longTermPriorityProp],
      ['assetInfo.image.mainHomeFurnishingActivity', mainHFActivityPrimaryProp],
      ['files.renditions.0.orientation', orientationProp],
      ['assetInfo.image.priceLevel', priceLevelProp],
      ['assetInfo.image.roomType', roomTypeProp],
      ['assetInfo.image.season', seasonICOM],
      ['assetInfo.image.styleGroup', styleGroup],
    ];

    for (const [idamKey, prop] of allProps) {
      if (prop.isError === true) {
        console.warn('Prop not found', prop);
        continue;
      }

      const out = extractBynderValue(idamKey, idamData.data, prop.result);

      for (const o of out) {
        if (typeof o.attrValue === 'undefined') {
          console.warn(`Option value not found for '${o.value}'`, o);
          continue;
        }

        activeCols.add(o.prop.id);

        const b = bagOfMeta[o.prop.id];

        if (Array.isArray(b)) {
          b.push(o.attrValue.id);
        } else {
          bagOfMeta[o.prop.id] = [o.attrValue.id];
        }
      }
    }

    if (Object.keys(bagOfMeta).length > 0) {
      const key = getUniqueKey({ type: 'idam-data', data: idamData.data });
      newData[key] = bagOfMeta;
    }
  }

  return { data: newData, columns: [...activeCols] };
}

export type GetMetadataPropertyReturnType = ReturnType<typeof getMetadataProperty>;

export interface BynderMetaFields {
  releaseProp: GetMetadataPropertyReturnType;
  launchProp: GetMetadataPropertyReturnType;
  objectiveProp: GetMetadataPropertyReturnType;
  cameraShotProp: GetMetadataPropertyReturnType;
  commonSeasonalActivityProp: GetMetadataPropertyReturnType;
  hasAnimalsProp: GetMetadataPropertyReturnType;
  hasPeopleProp: GetMetadataPropertyReturnType;
  longTermPriorityProp: GetMetadataPropertyReturnType;
  mainHFActivityPrimaryProp: GetMetadataPropertyReturnType;
  orientationProp: GetMetadataPropertyReturnType;
  priceLevelProp: GetMetadataPropertyReturnType;
  roomTypeProp: GetMetadataPropertyReturnType;
  seasonICOM: GetMetadataPropertyReturnType;
  styleGroup: GetMetadataPropertyReturnType;
}

function getFieldsFromMeta(bynderMeta: BynderMetaResponse): BynderMetaFields {
  // release date property
  const releaseProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_RELEASE_DATE_META_ID,
    bynderMeta,
    'release date'
  );

  const launchProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_INTENDED_TIMING_META_ID,
    bynderMeta,
    'launch / intended timing'
  );

  const objectiveProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_OBJECTIVE_META_ID,
    bynderMeta,
    'objective'
  );

  const cameraShotProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_CAMERA_SHOT_META_ID,
    bynderMeta,
    'camera shot'
  );

  const commonSeasonalActivityProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_COMMON_SEASONAL_ACTIVITY_META_ID,
    bynderMeta,
    'common seasonal activity'
  );

  const hasAnimalsProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_HAS_ANIMALS_META_ID,
    bynderMeta,
    'has animals'
  );

  const hasPeopleProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_HAS_PEOPLE_META_ID,
    bynderMeta,
    'has people'
  );
  const longTermPriorityProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_LONG_TIME_PRIORITY_META_ID,
    bynderMeta,
    'long term priority'
  );

  const mainHFActivityPrimaryProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_MAIN_HF_ACTIVITY_PRIMARY_META_ID,
    bynderMeta,
    'main hf activity'
  );

  const orientationProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_ORIENTATION_META_ID,
    bynderMeta,
    'orientation'
  );

  const priceLevelProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_PRICE_LEVEL_META_ID,
    bynderMeta,
    'price level'
  );

  const roomTypeProp = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_ROOM_TYPE_META_ID,
    bynderMeta,
    'room type'
  );

  const seasonICOM = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_SEASON_ICOM_META_ID,
    bynderMeta,
    'season icom'
  );

  const styleGroup = getMetadataProperty(
    bynderMetaFieldIDs.BYNDER_STYLE_GROUP_META_ID,
    bynderMeta,
    'style group'
  );

  return {
    releaseProp,
    launchProp,
    objectiveProp,
    cameraShotProp,
    commonSeasonalActivityProp,
    hasAnimalsProp,
    hasPeopleProp,
    longTermPriorityProp,
    mainHFActivityPrimaryProp,
    orientationProp,
    priceLevelProp,
    roomTypeProp,
    seasonICOM,
    styleGroup,
  };
}

export function checkBynderMeta(meta: BynderMetaResult): void {
  console.log('checking bynder meta...');
  if (meta.type !== 'bynder-meta-response') {
    return;
  }

  const fields = getFieldsFromMeta(meta);

  let k: keyof typeof fields;

  const fieldOptionNames = new Set<string>();

  for (k in fields) {
    const f = fields[k];
    if (f.isError === true) {
      console.warn(fields[k]);
    } else {
      f.result.options.forEach((o) => {
        fieldOptionNames.add(o.name);
      });
    }
  }

  // sanity check ensure all possible values for idam fields exists in some bynder option

  for (const v of Object.values(IDAMImageAttributeValueToBynderOptionTable)) {
    if (!fieldOptionNames.has(v)) {
      console.warn(`Could not find value '${v}' among bynder options.`);
    }
  }
  console.log('checking bynder meta: done');
}
