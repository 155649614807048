/** @jsxImportSource @emotion/react */

import Button from '@ingka/button';
import React, { useState } from 'react';
import { BASE_URL, useBynder } from '../../util/bynderReactUtils';
import Search from '@ingka/search';
import { IResult, Loadable, Loaded } from '../../util/type';
import {
  IBynderAddAssetsToCollectionResponse,
  IBynderCollection,
  IBynderShareCollectionResponse,
} from '../../util/bynder-api';
import PlusIcon from '@ingka/ssr-icon/paths/plus';
import DocumentAddIcon from '@ingka/ssr-icon/paths/document-add';
import TrashCanIcon from '@ingka/ssr-icon/paths/trash-can';
import ShareNetworkIcon from '@ingka/ssr-icon/paths/share-network';
import LinkOutIcon from '@ingka/ssr-icon/paths/link-out';
import Tooltip from '@ingka/tooltip';
import Modal, { Sheets, ModalBody, ModalHeader, ModalFooter } from '@ingka/modal';
import InputField from '@ingka/input-field';
import FormField from '@ingka/form-field';
import InlineMessage from '@ingka/inline-message';
import { LoadingBall } from '@ingka/loading';
import { ConditionalWrapper } from '../conditional-wrapper';
import { css } from '@emotion/react';

export interface IBynderBoardInputProps {
  allSelected?: boolean;
  isSelectingAll?: boolean;
  toggleSelectAll?: () => void;
  selectedAssetIds: string[];
  onBoardStatusChange?: (board: Loadable<ICollectionResult | IFailedCollectionResult>) => void;
  onAssetsAddedToBoard?: (res: IResult<IBynderAddAssetsToCollectionResponse>) => void;
}

export interface ICollectionResult {
  type: 'collection-result';
  collection?: IBynderCollection;
  assets: string[];
  name: string;
}

export interface IFailedCollectionResult {
  type: 'failed-collection-result';
  error: unknown;
}

export const BynderBoardInput = ({
  selectedAssetIds,
  onBoardStatusChange,
  onAssetsAddedToBoard,
  allSelected,
  isSelectingAll,
  toggleSelectAll,
}: IBynderBoardInputProps): JSX.Element => {
  const bynderWrap = useBynder();
  const [boardName, setBoardName] = useState<{ name: string; timeout?: number }>({ name: '' });
  const [result, setResult] = useState<
    Loadable<
      ICollectionResult | IFailedCollectionResult,
      { query: string; controller: AbortController | null }
    >
  >({
    status: 'uninitialized',
  });
  const [isCreatingBoard, setIsCreatingBoard] = useState(false);
  const [isAddingToBoard, setIsAddingToBoard] = useState(false);
  const [isRemovingFromBoard, setIsRemovingFromBoard] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const onChangeBoardName = (val: string, force = false) => {
    if (boardName.timeout) {
      clearTimeout(boardName.timeout);
    }

    setResult({ query: val, controller: null, status: 'loading' });

    const t = window.setTimeout(
      () => {
        void refreshNamedBoard(val, force);
      },
      val.length < 4 ? 0 : 500
    );
    setBoardName({ name: val, timeout: t });
  };

  const refreshNamedBoard = async (name: string, force = false) => {
    if (!bynderWrap.isLoggedIn) {
      return;
    }
    if (result.status === 'loading' && result.controller && !result.controller.signal.aborted) {
      result.controller.abort();
    }

    if ((name.length < 4 && !force) || name === '') {
      setResult({ status: 'uninitialized' });
      if (onBoardStatusChange) {
        onBoardStatusChange({ status: 'uninitialized' });
      }
      return;
    }

    const controller = new AbortController();

    const p = { query: name, controller };

    setResult({ ...p, status: 'loading' });
    if (onBoardStatusChange) {
      onBoardStatusChange({ status: 'loading' });
    }

    try {
      const data = await bynderWrap.bynder.getCollections(
        { keyword: name, limit: 1000, orderBy: 'dateCreated desc' },
        controller
      );

      if (data.isError) {
        if (data.type === 'request-aborted') {
          return;
        }

        console.error('Could not fetch collections', data);
        setResult({
          ...p,
          status: 'loaded',
          data: { type: 'failed-collection-result', error: data },
        });
        if (onBoardStatusChange) {
          onBoardStatusChange({
            status: 'loaded',
            data: { error: data, type: 'failed-collection-result' },
          });
        }
        return;
      }

      const board = data.result.find((a) => a.name === name);

      if (board) {
        const assets = await bynderWrap.bynder.getCollectionAssets(board.id, controller);
        if (assets.isError) {
          if (assets.type === 'request-aborted') {
            return;
          }

          console.log('could not fetch assets for board');

          const out: Loaded<IFailedCollectionResult> = {
            status: 'loaded',
            data: { type: 'failed-collection-result', error: data },
          };

          setResult({
            ...p,
            ...out,
          });
          if (onBoardStatusChange) {
            onBoardStatusChange(out);
          }
          return;
        }

        const out: Loaded<ICollectionResult> = {
          status: 'loaded',
          data: { type: 'collection-result', assets: assets.result, collection: board, name },
        };

        setResult({
          ...p,
          ...out,
        });
        if (onBoardStatusChange) {
          onBoardStatusChange(out);
        }
      } else {
        const out: Loaded<ICollectionResult> = {
          status: 'loaded',
          data: { type: 'collection-result', assets: [], collection: board, name },
        };

        setResult({ ...p, ...out });

        if (onBoardStatusChange) {
          onBoardStatusChange(out);
        }
      }
    } catch (e) {
      console.log('collection call failed', e);
      setResult({ ...p, status: 'loaded', data: { type: 'failed-collection-result', error: e } });
    }
  };

  const onCreateBoard = async () => {
    console.log(`create "${boardName.name}"`);
    if (bynderWrap.isLoggedIn === false) {
      return;
    }

    setIsCreatingBoard(true);

    const r = await bynderWrap.bynder.createCollection({ name: boardName.name });

    setIsCreatingBoard(false);
    if (r.isError) {
      alert('Could not create board');
      return;
    }

    void refreshNamedBoard(boardName.name);
  };

  const onAddAssetsToBoard = async (collection: IBynderCollection, assets: string[]) => {
    if (bynderWrap.isLoggedIn === false) {
      return;
    }

    setIsAddingToBoard(true);
    const res = await bynderWrap.bynder.addAssetsToCollection(collection.id, assets);
    setIsAddingToBoard(false);

    if (res.isError) {
      alert('Failed to add assets to board');
      console.error(res);
      return;
    }

    void refreshNamedBoard(collection.name);
    if (onAssetsAddedToBoard) {
      onAssetsAddedToBoard(res);
    }
  };

  const onRemoveAssetsFromBoard = async (collection: IBynderCollection, assets: string[]) => {
    if (bynderWrap.isLoggedIn === false) {
      return;
    }

    setIsRemovingFromBoard(true);
    const res = await bynderWrap.bynder.removeAssetsFromCollection(collection.id, assets);
    setIsRemovingFromBoard(false);

    if (res.isError) {
      alert('Failed to remove assets from board');
      console.error(res);
      return;
    }

    void refreshNamedBoard(collection.name);
  };

  let bynderBoardCreate;
  let bynderBoardAdd;
  let bynderBoardRemove;
  let bynderBoardShare;
  let bynderBoardLink;

  if (result.status === 'uninitialized' || bynderWrap.isLoggedIn === false) {
    bynderBoardCreate = (
      <Tooltip tooltipText={'Create board'}>
        <Button ssrIcon={PlusIcon} iconOnly={true} disabled={true} type={'tertiary'} />
      </Tooltip>
    );
    bynderBoardAdd = (
      <Tooltip tooltipText={'Add to board'}>
        <Button ssrIcon={DocumentAddIcon} iconOnly={true} disabled={true} type={'tertiary'} />
      </Tooltip>
    );
    bynderBoardRemove = (
      <Tooltip tooltipText={'Remove from board'}>
        <Button ssrIcon={TrashCanIcon} iconOnly={true} disabled={true} type={'tertiary'} />
      </Tooltip>
    );
    bynderBoardShare = (
      <Tooltip tooltipText={'Share board'}>
        <Button ssrIcon={ShareNetworkIcon} iconOnly={true} disabled={true} type={'tertiary'} />
      </Tooltip>
    );
    bynderBoardLink = (
      <Tooltip tooltipText={'View board in Bynder'}>
        <Button ssrIcon={LinkOutIcon} iconOnly={true} disabled={true} type={'tertiary'} />
      </Tooltip>
    );
  } else if (
    result.status === 'loading' ||
    isAddingToBoard ||
    isCreatingBoard ||
    isRemovingFromBoard
  ) {
    bynderBoardCreate = (
      <Tooltip tooltipText={'Create board'}>
        <Button
          ssrIcon={PlusIcon}
          iconOnly={true}
          loading={true}
          disabled={true}
          type={'tertiary'}
        />
      </Tooltip>
    );
    bynderBoardAdd = (
      <Tooltip tooltipText={'Add to board'}>
        <Button
          ssrIcon={DocumentAddIcon}
          iconOnly={true}
          loading={true}
          type={'tertiary'}
          disabled={true}
        />
      </Tooltip>
    );
    bynderBoardRemove = (
      <Tooltip tooltipText={'Remove from board'}>
        <Button
          ssrIcon={DocumentAddIcon}
          iconOnly={true}
          loading={true}
          type={'tertiary'}
          disabled={true}
        />
      </Tooltip>
    );
    bynderBoardShare = (
      <Tooltip tooltipText={'Share board'}>
        <Button
          ssrIcon={ShareNetworkIcon}
          iconOnly={true}
          disabled={true}
          type={'tertiary'}
          loading={true}
        />
      </Tooltip>
    );
    bynderBoardLink = (
      <Tooltip tooltipText={'View board in Bynder'}>
        <Button
          ssrIcon={LinkOutIcon}
          iconOnly={true}
          disabled={true}
          type={'tertiary'}
          loading={true}
        />
      </Tooltip>
    );
  } else if (result.data.type === 'collection-result') {
    const col = result.data.collection;
    if (col) {
      bynderBoardCreate = (
        <Tooltip tooltipText={`Board '${col.name}' already exists.`}>
          <Button ssrIcon={PlusIcon} iconOnly={true} disabled={true} type={'tertiary'} />
        </Tooltip>
      );
      bynderBoardLink = (
        <Tooltip tooltipText={'View board in Bynder'}>
          <Button
            ssrIcon={LinkOutIcon}
            iconOnly={true}
            type={'tertiary'}
            href={`${BASE_URL}/collections/view/${col.id.toUpperCase()}/`}
            newWindow={true}
          />
        </Tooltip>
      );

      if (selectedAssetIds.length > 0) {
        bynderBoardAdd = (
          <Tooltip tooltipText={`Add ${selectedAssetIds.length} assets to '${col.name}'`}>
            <Button
              ssrIcon={DocumentAddIcon}
              iconOnly={true}
              type={'tertiary'}
              onClick={() => onAddAssetsToBoard(col, selectedAssetIds)}
            />
          </Tooltip>
        );
        bynderBoardRemove = (
          <Tooltip tooltipText={`Remove ${selectedAssetIds.length} assets from '${col.name}'`}>
            <Button
              ssrIcon={TrashCanIcon}
              iconOnly={true}
              type={'tertiary'}
              onClick={() => onRemoveAssetsFromBoard(col, selectedAssetIds)}
            />
          </Tooltip>
        );
      } else {
        bynderBoardAdd = (
          <Tooltip tooltipText={`Select DAM assets to add to '${col.name}'`}>
            <Button ssrIcon={DocumentAddIcon} iconOnly={true} disabled={true} type={'tertiary'} />
          </Tooltip>
        );
        bynderBoardRemove = (
          <Tooltip tooltipText={`Select DAM assets to remove from '${col.name}'`}>
            <Button ssrIcon={TrashCanIcon} iconOnly={true} type={'tertiary'} disabled={true} />
          </Tooltip>
        );
      }

      bynderBoardShare = (
        <>
          <Tooltip tooltipText={'Share board'}>
            <Button
              ssrIcon={ShareNetworkIcon}
              iconOnly={true}
              type={'tertiary'}
              onClick={() => setShareModalOpen(true)}
            />
          </Tooltip>
          <ShareBoardModal
            open={shareModalOpen}
            onClose={() => setShareModalOpen(false)}
            collection={col}
          />
        </>
      );
    } else {
      bynderBoardCreate = (
        <Tooltip tooltipText={`Create board '${result.data.name}'`}>
          <Button
            ssrIcon={PlusIcon}
            iconOnly={true}
            type={'tertiary'}
            onClick={() => onCreateBoard()}
          />
        </Tooltip>
      );
      bynderBoardAdd = (
        <Tooltip tooltipText={`Create board before adding assets.`}>
          <Button ssrIcon={DocumentAddIcon} iconOnly={true} disabled={true} type={'tertiary'} />
        </Tooltip>
      );
      bynderBoardRemove = (
        <Tooltip tooltipText={'Create board before removing assets from it.'}>
          <Button ssrIcon={TrashCanIcon} iconOnly={true} type={'tertiary'} disabled={true} />
        </Tooltip>
      );
      bynderBoardShare = (
        <Tooltip tooltipText={'Create board before sharing'}>
          <Button ssrIcon={ShareNetworkIcon} iconOnly={true} type={'tertiary'} disabled={true} />
        </Tooltip>
      );
      bynderBoardLink = (
        <Tooltip tooltipText={'Board does not exist.'}>
          <Button ssrIcon={LinkOutIcon} iconOnly={true} disabled={true} type={'tertiary'} />
        </Tooltip>
      );
    }
  }

  const selectAllContent = isSelectingAll ? (
    <LoadingBall size="small" />
  ) : allSelected ? (
    'Unselect all'
  ) : (
    'Select all'
  );

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
      `}
    >
      <ConditionalWrapper
        condition={bynderWrap.isLoggedIn === false}
        wrapper={(children) => (
          <Tooltip tooltipText={'You must login to bynder to interact with boards.'}>
            {children}
          </Tooltip>
        )}
      >
        <Search
          type="string"
          id="board_name"
          placeholder="Board name"
          value={boardName.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeBoardName(e.currentTarget.value)
          }
          onSearch={() => onChangeBoardName(boardName.name, true)}
          onClear={() => onChangeBoardName('', true)}
          css={css`
            width: 300px;
            margin-right: 10px;
          `}
          disabled={
            isAddingToBoard || isCreatingBoard || isRemovingFromBoard || !bynderWrap.isLoggedIn
          }
        />
      </ConditionalWrapper>

      <div
        css={css`
          flex: 1;

          > * {
            margin-right: 10px;
          }
        `}
      >
        {bynderBoardCreate}
        {bynderBoardAdd}
        {bynderBoardRemove}
        {bynderBoardShare}
        {bynderBoardLink}
      </div>
      {toggleSelectAll && (
        <Button
          onClick={toggleSelectAll}
          small
          type="tertiary"
          disabled={isSelectingAll}
          css={css`
            align-self: center;
          `}
        >
          {selectAllContent}
        </Button>
      )}
    </div>
  );
};

function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

interface IShareBoardModalProps {
  open: boolean;
  onClose: () => void;
  collection: IBynderCollection;
}

export interface IShareCollection {
  type: 'collection-shared';
  response: IBynderShareCollectionResponse;
}

export interface IFailedShareCollection {
  type: 'failed-to-share-collection';
  error: unknown;
}

const ShareBoardModal = (props: IShareBoardModalProps) => {
  const bynderWrap = useBynder();
  const { open, onClose, collection } = props;
  const [recipient, setRecipient] = useState(() => {
    const s = localStorage.getItem('share_board_recipient');
    if (s === null) {
      return '';
    } else {
      return s;
    }
  });
  const [result, setResult] = useState<Loadable<IShareCollection | IFailedShareCollection>>({
    status: 'uninitialized',
  });

  const handleSubmit = async (recipient: string) => {
    if (!validateEmail(recipient)) {
      console.log('invalid email');
      return;
    }

    if (!bynderWrap.isLoggedIn) {
      return;
    }

    setResult({ status: 'loading' });

    const res = await bynderWrap.bynder.shareCollection({
      id: collection.id,
      recipients: [recipient],
      collectionOptions: 'edit',
    });

    if (res.isError) {
      console.error(res);
      setResult({ status: 'loaded', data: { type: 'failed-to-share-collection', error: res } });
      return;
    }

    setResult({ status: 'loaded', data: { type: 'collection-shared', response: res.result } });
    localStorage.setItem('share_board_recipient', recipient);
  };

  const isValid = validateEmail(recipient);

  let status;

  if (result.status === 'loaded') {
    if (result.data.type === 'failed-to-share-collection') {
      status = (
        <InlineMessage
          variant={'negative'}
          title={'Could not share board.'}
          css={css`
            margin-top: 30px;
          `}
          body={<pre>{JSON.stringify(result.data.error, null, 4)}</pre>}
        />
      );
    } else {
      status = (
        <InlineMessage
          variant={'positive'}
          title={'User added.'}
          css={css`
            margin-top: 30px;
          `}
          body={
            <p>
              {recipient} was added to &quot;{collection.name}&quot;.
            </p>
          }
        />
      );
    }
  }

  return (
    <Modal
      visible={open} // Opens / Closes modal
      escapable={false} // the modal view can't be dismissed without interacting with modal content
      handleCloseBtn={onClose} // on Internal close request
    >
      <Sheets
        title="Share board with users" // Prompt's heading
        header={<ModalHeader></ModalHeader>}
        footer={
          <ModalFooter>
            <Button
              text="Share board"
              type="primary"
              onClick={() => handleSubmit(recipient)}
              disabled={!isValid}
              loading={result.status === 'loading'}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <p>Share the board {`"${collection.name}"`}</p>

          <FormField
            valid={isValid}
            shouldValidate={recipient.length > 0}
            validation={{
              msg: 'Recipient must be a valid e-mail.',
              type: 'error',
              id: 'invalid-email',
            }}
          >
            <InputField
              label="Recipient e-mail"
              id="receipient"
              type="text"
              autoFocus={true}
              value={recipient}
              disabled={result.status === 'loading'}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Enter') {
                  void handleSubmit(recipient);
                }
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setRecipient(e.currentTarget.value);
                if (result.status !== 'uninitialized') {
                  setResult({ status: 'uninitialized' });
                }
              }}
            />
          </FormField>
          {status}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
