import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link } from 'react-router-dom';
import { useBynder } from '../util/bynderReactUtils';
import { ok } from '../util/type';
import { Grid } from './grid';

export const BynderBoardViewer = () => {
  const bynder = useBynder();

  const { data: boardsResult, isLoading } = useQuery(['boards', bynder.isLoggedIn], async () => {
    if (!bynder.isLoggedIn) {
      return ok([]);
    }

    const results = [];

    let page = 1;

    for (;;) {
      const collections = await bynder.bynder.getCollections(
        { limit: 1000, page },
        new AbortController()
      );

      if (collections.isError === true) {
        return collections;
      }

      results.push(...collections.result);

      if (collections.result.length < 1000) {
        break;
      }

      page += 1;
    }

    return ok(results);
  });

  if (!bynder.isLoggedIn) {
    return <div>Bynder not loaded</div>;
  }

  if (isLoading) {
    return <div>Loading collections</div>;
  }

  if (!boardsResult || boardsResult.isError) {
    return <div>Error loading collections {boardsResult?.type}</div>;
  }

  const cards = boardsResult.result.map((q) => {
    return (
      <div key={q.id}>
        <Link to={`/crop/boards/${q.id}`}>
          <img src={q.cover.thumbnail} alt={q.name} />
        </Link>
        <div>Name: {q.name}</div>
        <div>Assets: {q.collectionCount}</div>
      </div>
    );
  });

  return (
    <Grid columns={5} colGap={20} rowGap={20}>
      {cards}
    </Grid>
  );
};
