import { hasKey, hasStringKey } from '@ikea-ingka-dam/type-util';
import {
  GetBynderAssetUploadJobListResult,
  PIABulkItemEntry,
  PIAItemIdentifier,
} from '../../generated-backend-api';
import { BynderApiAssetType, BynderKeywordSearchResponse } from '../../util/bynder-api';
import { ProductCoordinateList, ProductCoordinateListType } from '../../util/product-coordinates';

/**
 * Extract PiaItemIdentifiers from product coordinates list.
 * Ex: art,1234567891 ->  { type: ART, artNo: 1234567891 }
 */
export function productCoordinatesToPIAItemIdentifiers(products: ProductCoordinateListType): {
  itemIdentifiers: PIAItemIdentifier[];
  skippedProducts: string[];
} {
  const itemIdentifiers: PIAItemIdentifier[] = [];

  const skippedProducts = [];

  for (const p of products) {
    // make sure its a valid item number
    if (p.prd.indexOf(',') === -1) {
      skippedProducts.push(p.prd);
      continue;
    }

    const [type, artNo] = p.prd.split(',').map((q) => q.trim().toUpperCase());

    if (type !== 'ART' && type !== 'SPR') {
      skippedProducts.push(p.prd);
      continue;
    }

    itemIdentifiers.push({
      artNo,
      type,
    });
  }

  return { itemIdentifiers, skippedProducts };
}

/**
 * Extract the asset name without the crop suffix.
 */
export function assetNameWithoutCrop(name: string): string {
  const cropIndex = name.toLowerCase().indexOf('-crop');
  const cleanName = cropIndex === -1 ? name : name.substring(0, cropIndex);

  return cleanName;
}

/**
 * Generate a new unique asset name.
 * Checks bynder and upload queue for existing asset names.
 */
export async function generateCropName(
  searchBynderByKeyword: (keyword: string) => Promise<BynderKeywordSearchResponse>,
  asset: BynderApiAssetType,
  getJobsByName: (name: string) => Promise<GetBynderAssetUploadJobListResult>
): Promise<string | null> {
  // fetch existing assets

  const names = await searchBynderByKeyword(assetNameWithoutCrop(asset.name));

  if (names.isError) {
    console.error(names);
    return null;
  }

  const extension = asset.extension.length < 1 ? '' : asset.extension[0];

  // construct all name extension pairs returned from bynder & test different crop versions of the name for the asset.

  const uniqueNamesWithExtensions = new Set<string>();

  for (const a of names.result) {
    let ext = '';
    if (!hasKey('extension', a) || !Array.isArray(a.extension)) {
      // no valid extension
      continue;
    }

    if (a.extension.length === 1 && typeof a.extension[0] === 'string') {
      ext = a.extension[0];
    }

    if (!hasStringKey('name', a)) {
      // no valid name
      continue;
    }
    uniqueNamesWithExtensions.add(`${a.name}.${ext}`);
  }

  const cleanName = assetNameWithoutCrop(asset.name);

  for (let i = 1; i < 1000; i++) {
    const cropName = `${cleanName}-crop${String(i).padStart(3, '0')}`;
    if (!uniqueNamesWithExtensions.has(`${cropName}.${extension}`)) {
      // found a unique name

      // check if there is an asset with the same name queued
      const r = await getJobsByName(cropName);

      if (r.type !== 'bynder-asset-upload-job-list-response') {
        console.error('Unexpected response from getJobsByName', r);
        continue;
      }

      if (r.result.find((q) => q.item.name === cropName)) {
        console.warn('Found a job with the same name, trying next one', cropName);
        continue;
      }

      return cropName;
    }
  }

  return null;
}

/**
 * Extract product coordinates from a bynder asset.
 */
export function extractBynderProductCoords(asset: BynderApiAssetType): ProductCoordinateListType {
  if (
    !asset.property_product_coordinates ||
    typeof asset.property_product_coordinates !== 'string'
  ) {
    return [];
  }

  try {
    const d: unknown = JSON.parse(asset.property_product_coordinates);

    if (!ProductCoordinateList.is(d)) {
      return [];
    }

    return d;
  } catch (e) {
    console.warn(e);
    console.warn('failed to parse coords', asset.property_product_coordinates);
    return [];
  }
}

/**
 * Extracts PIA item media url if it exists in a PIABulkItemEntry item.
 */
export function getPIAItemMedia(item: PIABulkItemEntry | undefined): null | string {
  if (!item || !item.content?.itemMedia) {
    return null;
  }

  const media = item.content.itemMedia.find((m) => m.mediaTypeName === 'Main Product Picture');

  if (!media || !media.mediaContent) {
    return null;
  }

  const mediaContent = media.mediaContent.find((m) => m.mediaFileTypeName === 'JPEG Image');

  if (!mediaContent || !mediaContent._links?.download.href) {
    return null;
  }

  return mediaContent._links.download.href;
}
