import { MetaProperty } from '../generated-backend-api';
import { BynderApiAssetType } from './bynder-api';

// this file is adapted from image-tagging/backend/lib/metaCopyWorker.js

// type helpers
type OptionId = string;
type MetaId = string;

/**
 * Constructs a map of metaproperty options to their respective metaproperty id.
 */
function getMetapropertyOptionToMetaPropertyIdMap(
  metaproperties: Record<string, MetaProperty>
): Map<OptionId, MetaId> {
  const optionToMetaIdMap = new Map<string, string>();

  Object.keys(metaproperties).forEach((key) => {
    const metaproperty = metaproperties[key];
    if (metaproperty.options.length > 0) {
      metaproperty.options.forEach((option) => {
        optionToMetaIdMap.set(option.id, metaproperty.id);
      });
    }
  });

  return optionToMetaIdMap;
}

function getBynderFormPropsForPropertyOptions(
  propertyOptions: OptionId[],
  optionIdToMetaIdMap: Map<OptionId, MetaId>
) {
  const modifyAssetForm = new Map<string, OptionId[]>();

  for (const optionId of propertyOptions) {
    const metaId = optionIdToMetaIdMap.get(optionId);

    if (!metaId) {
      continue;
    }

    const metaKey = `metaproperty.${metaId}`;

    const optionIds = modifyAssetForm.get(metaKey);
    if (optionIds) {
      optionIds.push(optionId);
    } else {
      modifyAssetForm.set(metaKey, [optionId]);
    }
  }

  // metakey -> optionId,optionId,optionId...

  const out: { [key: string]: string } = {};

  for (const [key, optionsList] of modifyAssetForm) {
    out[key] = optionsList.join(',');
  }

  return out;
}

function getBynderFormPropsForAssetProperties(
  asset: BynderApiAssetType,
  metaproperties: Record<string, MetaProperty | undefined>
): Record<string, string | number> {
  const propertiesReq: Record<string, string | number> = {};

  for (const [key, val] of Object.entries(asset)) {
    const propertyName = key.slice('property_'.length);

    const m = metaproperties[propertyName];

    if (!m) {
      continue;
    }

    const propertyId = m.id;
    let v: string | number;
    if (Array.isArray(val)) {
      v = val.join(',');
    } else if (typeof val === 'number' || typeof val === 'string') {
      v = val;
    } else {
      console.warn(
        'Unsupported property type in bynder asset to bynder form conversion, ignoring.',
        key,
        val
      );
      continue;
    }

    propertiesReq[`metaproperty.${propertyId}`] = v;
  }

  return propertiesReq;
}

interface BynderAssetToBynderFormOptions {
  bynderMetaProperties: Record<string, string | number>;
  bynderMetaPropertyOptions: Record<string, string>;
  bynderProperties: BynderProperties;
}

interface BynderProperties {
  name: string;
  tags: string;
  copyright: string;
  description: string;
  brandId: string;
  archive: number;
  datePublished: string;
}

/**
 * Extracts bynder form properties (ie: form data that can be sent to bynder) from a bynder asset.
 */
export function bynderAssetToBynderForm(
  asset: BynderApiAssetType,
  metaproperties: Record<string, MetaProperty>
): BynderAssetToBynderFormOptions {
  const optionIdToMetaIdMap = getMetapropertyOptionToMetaPropertyIdMap(metaproperties);

  const propertyOptions = asset.propertyOptions
    ? getBynderFormPropsForPropertyOptions(asset.propertyOptions, optionIdToMetaIdMap)
    : {};

  const properties = getBynderFormPropsForAssetProperties(asset, metaproperties);

  const bynderProperties = {
    name: asset.name,
    tags: asset.tags ? asset.tags.join(',') : '',
    copyright: asset.copyright,
    description: asset.description,
    brandId: asset.brandId,
    archive: asset.archive,
    datePublished: asset.datePublished,
  } as const;

  return {
    bynderMetaProperties: properties,
    bynderMetaPropertyOptions: propertyOptions,
    bynderProperties,
  };
}
